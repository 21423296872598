import React from 'react'
import { FaReact, FaJsSquare, FaCog, FaNodeJs, FaPython } from 'react-icons/fa'
import { GrGatsbyjs, GrGraphQl } from 'react-icons/gr'
import { SiZapier, SiMongodb, SiTypescript } from 'react-icons/si'
import { GiHolosphere, GiRadioTower } from 'react-icons/gi'

export const TagContainer = ({ children }) => {
  return (
    <div className="tag__container">
      {children}
    </div>
  )
}

const Icon = ({ subject }) => {
  switch (subject) {
    case 'React':
      return <FaReact />
    case 'Javascript':
      return <FaJsSquare />
    case 'Gatsby':
      return <GrGatsbyjs />
    case 'Node':
      return <FaNodeJs />
    case 'Express':
      return <FaJsSquare />
    case 'GraphQL':
      return <GrGraphQl />
    case 'Zapier':
      return <SiZapier />
    case 'MongoDB':
      return <SiMongodb />
    case 'NextJS':
      return (
        <svg fill="currentColor" height="1em" width="1em" viewBox=".5 -.2 1023 1024.1" xmlns="http://www.w3.org/2000/svg">
          <path d="m478.5.6c-2.2.2-9.2.9-15.5 1.4-145.3 13.1-281.4 91.5-367.6 212-48 67-78.7 143-90.3 223.5-4.1 28.1-4.6 36.4-4.6 74.5s.5 46.4 4.6 74.5c27.8 192.1 164.5 353.5 349.9 413.3 33.2 10.7 68.2 18 108 22.4 15.5 1.7 82.5 1.7 98 0 68.7-7.6 126.9-24.6 184.3-53.9 8.8-4.5 10.5-5.7 9.3-6.7-.8-.6-38.3-50.9-83.3-111.7l-81.8-110.5-102.5-151.7c-56.4-83.4-102.8-151.6-103.2-151.6-.4-.1-.8 67.3-1 149.6-.3 144.1-.4 149.9-2.2 153.3-2.6 4.9-4.6 6.9-8.8 9.1-3.2 1.6-6 1.9-21.1 1.9h-17.3l-4.6-2.9c-3-1.9-5.2-4.4-6.7-7.3l-2.1-4.5.2-200.5.3-200.6 3.1-3.9c1.6-2.1 5-4.8 7.4-6.1 4.1-2 5.7-2.2 23-2.2 20.4 0 23.8.8 29.1 6.6 1.5 1.6 57 85.2 123.4 185.9s157.2 238.2 201.8 305.7l81 122.7 4.1-2.7c36.3-23.6 74.7-57.2 105.1-92.2 64.7-74.3 106.4-164.9 120.4-261.5 4.1-28.1 4.6-36.4 4.6-74.5s-.5-46.4-4.6-74.5c-27.8-192.1-164.5-353.5-349.9-413.3-32.7-10.6-67.5-17.9-106.5-22.3-9.6-1-75.7-2.1-84-1.3zm209.4 309.4c4.8 2.4 8.7 7 10.1 11.8.8 2.6 1 58.2.8 183.5l-.3 179.8-31.7-48.6-31.8-48.6v-130.7c0-84.5.4-132 1-134.3 1.6-5.6 5.1-10 9.9-12.6 4.1-2.1 5.6-2.3 21.3-2.3 14.8 0 17.4.2 20.7 2z" /><path d="m784.3 945.1c-3.5 2.2-4.6 3.7-1.5 2 2.2-1.3 5.8-4 5.2-4.1-.3 0-2 1-3.7 2.1zm-6.9 4.5c-1.8 1.4-1.8 1.5.4.4 1.2-.6 2.2-1.3 2.2-1.5 0-.8-.5-.6-2.6 1.1zm-5 3c-1.8 1.4-1.8 1.5.4.4 1.2-.6 2.2-1.3 2.2-1.5 0-.8-.5-.6-2.6 1.1zm-5 3c-1.8 1.4-1.8 1.5.4.4 1.2-.6 2.2-1.3 2.2-1.5 0-.8-.5-.6-2.6 1.1zm-7.6 4c-3.8 2-3.6 2.8.2.9 1.7-.9 3-1.8 3-2 0-.7-.1-.6-3.2 1.1z" />
        </svg>
      )
    case 'Apollo':
      return (
        <svg fill="currentColor" height="1em" width="1em" viewBox="26.69 28.9 1173.9 1144.1" xmlns="http://www.w3.org/2000/svg">
          <path d="m599.49 29.7c-2.2.1-10.5.8-18.5 1.4-100 7.4-201.4 44.4-284.5 103.8-27.5 19.6-49.2 38-73.4 62.2-139.1 139.2-196.4 338.4-152.9 531.9 17.8 79.4 55.8 159.3 106.2 223.5 79.1 100.9 187.5 172 309.1 202.8 49.5 12.6 90.6 17.7 142.5 17.7 51.2 0 88.9-4.6 137.7-16.5 70.1-17.2 131.7-45.5 193.3-88.6 59-41.4 113.9-99.4 153.3-161.9 50.8-80.8 79.7-170 86.8-267.9 1.5-20.6.6-71.6-1.6-92.6-4.4-41.7-15.6-96.7-22.1-108.2-2.6-4.5-9.9-10.3-15.3-12.2-1.9-.6-6.1-1.1-9.5-1.1-15 0-27.6 12.5-27.6 27.2 0 3.5 1.6 11.9 3.9 21 19.8 77 21.4 161.6 4.5 238.8-21.6 98.9-69.8 186.1-142.5 257.6-33.9 33.3-66.6 57.9-108.9 82.1-98.7 56.5-221.4 77.9-336.5 58.7-87.4-14.5-163.3-48.1-235.4-104.2-33.9-26.4-75.2-69.9-100.8-106.3-44.2-62.8-72-129-86.4-205.9-7.3-39.2-9.8-91.2-6.4-133.5 5.2-64.2 21.3-123.8 48.6-180.3 12.6-26.3 20.4-40 35.5-62.7 30.8-46.6 71.6-90.2 116.7-124.8 52.9-40.7 115.7-71.6 179.2-88.2 34.3-9 61.7-13.5 96.5-16 125.9-9 248.3 27.2 348 103.1 7.4 5.6 15.2 11.7 17.3 13.6l3.8 3.3-1.5 4.2c-7.7 21.6-2 48.5 14.2 67.3 8.3 9.6 23.3 18.6 36 21.6 8.7 2.1 23.8 2.1 32.6 0 22.3-5.3 43.1-24.4 49.7-45.6 7.4-23.7 3.1-47.1-12.1-66.1-16.8-21-45.7-30.4-72.4-23.4l-5.8 1.5-8.2-6.8c-33.3-27.9-73.6-53.7-116-74.2-68.8-33.3-137.9-51.2-216.6-56-15.1-.9-50.8-1.1-60.5-.3z" /><path d="m540.29 358.7c-10.6 27.4-33.6 87.4-51.3 133.3s-54 140.2-80.7 209.5c-26.6 69.3-48.7 126.3-48.9 126.7-.3.5 27.8.8 62.3.8h62.9l3.7-10.3c2.1-5.6 9.5-25.4 16.4-44l12.6-33.7h94.4c75.2 0 94.4-.3 94.1-1.3-1-2.7-16.4-46.7-24.6-70.2l-8.8-25-62.5-.5-62.4-.5 40.6-111.9c24.4-67.2 40.9-111.2 41.2-110.2.4.9 16.6 47 36.1 102.4s51.7 146.6 71.5 202.7l35.9 102 62.6.3c34.4.1 62.6 0 62.6-.2s-8.1-21.5-18.1-47.2c-10-25.8-31.4-81.3-47.6-123.4s-34.4-89.3-40.5-105c-6-15.7-16.5-42.9-23.3-60.5s-24.5-63.7-39.5-102.5-28.1-72.9-29.2-75.8l-1.9-5.2h-138.5z" />
        </svg>
      )
    case 'KeystoneJS':
      return (
        <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg">
          <path d="M290.136 47H407.58c17.83 0 24.297 1.857 30.815 5.343 6.519 3.486 11.634 8.602 15.12 15.12 3.487 6.519 5.343 12.984 5.343 30.815v117.444c0 17.83-1.856 24.296-5.343 30.815-3.486 6.518-8.601 11.634-15.12 15.12-6.518 3.486-12.984 5.343-30.815 5.343H290.136c-17.83 0-24.296-1.857-30.815-5.343-6.518-3.486-11.634-8.602-15.12-15.12-3.486-6.519-5.343-12.984-5.343-30.815V98.278c0-17.83 1.857-24.296 5.343-30.815 3.486-6.518 8.602-11.634 15.12-15.12C265.84 48.857 272.305 47 290.136 47zm11.762 56.76V218h25.12v-36.8l14.4-14.56 34.4 51.36h31.52l-48.96-69.12 44.64-45.12h-31.36l-44.64 47.36v-47.36h-25.12z" transform="translate(-238.858 -47)" fillRule="evenodd" />
        </svg>
      )
    case 'ThreeJS':
      return (
        <svg fill="currentColor" width="1em" height="1em" strokeLinecap="square" strokeMiterlimit="10" version="1.1" viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(8.964 4.2527)" fillRule="evenodd" stroke="#000" strokeLinecap="butt" strokeLinejoin="round" strokeWidth="4">
            <path d="m63.02 200.61-43.213-174.94 173.23 49.874z" />
            <path d="m106.39 50.612 21.591 87.496-86.567-24.945z" />
            <path d="m84.91 125.03-10.724-43.465 43.008 12.346z" />
            <path d="m63.458 38.153 10.724 43.465-43.008-12.346z" />
            <path d="m149.47 62.93 10.724 43.465-43.008-12.346z" />
            <path d="m84.915 125.06 10.724 43.465-43.008-12.346z" />
          </g>
        </svg>
      )
    case 'react-three-fiber':
      return (
        <GiHolosphere />
      )
    case 'IoT':
      return (
        <GiRadioTower />
      )
    case 'Python':
      return (
        <FaPython />
      )
    case 'Typescript':
      return (
        <SiTypescript />
      )
    default:
      return <FaCog />
  }
}

export const Tag = ({ subject }) => {
  return <div className={`tag__item tag__item-${subject}`}><Icon subject={subject} /> {subject}</div>
}
